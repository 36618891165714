import React, { useState, useEffect } from "react";

interface FillTextProps {
  text: string,
  color: string,
}

export function FillText({ text, color }: FillTextProps) {
  const [top, setTop] = useState(10);
  const [width, setWidth] = useState(160);
  const [height, setHeight] = useState(0);
  useEffect(() => {
    const measure = async () => {
      await (document as any).fonts.load("10px Orbitron");
      const offscreen = new OffscreenCanvas(16, 16);
      const ctx = offscreen.getContext("2d");
      ctx!.font = '10px "Orbitron"';
      const metrics = ctx!.measureText(text);
      console.log(metrics);
      setWidth(metrics.width);
      setTop(-1 * metrics.actualBoundingBoxAscent - 0.25);
      setHeight(metrics.actualBoundingBoxDescent + metrics.actualBoundingBoxAscent + 0.5);
    }

    measure();
  }, [text, setTop, setWidth, setHeight]);

  const viewBox = `0 ${top} ${width} ${height}`;
  return (
    <svg viewBox={viewBox}>
      <text x="0" y="0" style={{ font: "10px Orbitron", fill: color }}>{text}</text>
    </svg>
  );
}

