import React, { useState, useEffect } from "react";
import { getDateParts } from "./Utils";
import { FillText } from "./FillText";

export function Today() {
  const [day, setDay] = useState("Monday - April 27");

  useEffect(() => {
    const cb = () => {
      const now = getDateParts(new Date());
      setDay(`${now.weekday} - ${now.month} ${now.day}`);
    };
    const timer = setInterval(cb, 1000);
    return () => clearInterval(timer);
  }, [setDay]);

  return (
    <div className="today">
      <FillText text={day} color={"#00b200"} />
    </div>
  );
}
