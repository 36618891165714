import React, { useRef } from 'react';
import './App.css';
import { Time } from './Time';
import { Today } from './Today';

function App() {
  const divRef = useRef<HTMLDivElement>(null);
  const onClick = () => {
    if (document.fullscreenElement)
      document.exitFullscreen();
    else
      divRef.current!.requestFullscreen();
  };

  return (
    <div ref={divRef} className="App" onClick={onClick}>
      <Time />
      <Today />
    </div>
  );
}

export default App;
