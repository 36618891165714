const datePartsKeys = ["weekday", "month", "day", "year", "hour", "minute", "second", "dayPeriod"] as const;
type DateParts = { [K in typeof datePartsKeys[number]]: string }
const formatter = new Intl.DateTimeFormat("en-us", {
  weekday: "long",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  hour12: true,
});

export function getDateParts(date: Date): DateParts {
  const partsArray = formatter.formatToParts(date);
  const partsObj = {} as any;
  for (const { type, value } of partsArray) {
    if (datePartsKeys.includes(type as any))
      partsObj[type] = value;
  }
  return partsObj;
}