import React, { useState, useEffect } from 'react';
import { getDateParts } from './Utils';
import { FillText } from './FillText';

export function Time() {
  const [hours, setHours] = useState("12");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [period, setPeriod] = useState("AM");

  useEffect(() => {
    const cb = () => {
      const now = getDateParts(new Date());
      setHours(now.hour)
      setMinutes(now.minute);
      setSeconds(now.second);
      setPeriod(now.dayPeriod);
    };
    const timer = setInterval(cb, 1000);
    return () => clearInterval(timer);
  }, [setHours, setMinutes, setSeconds, setPeriod]);

  return (
    <div className="time">
      <span className="hour-minutes"><FillText text={`${hours}:${minutes}`} color="#0F0" /></span>
      <span className="small ampm">{toDigits(period + "M")}</span>
      <span className="small seconds">{toDigits(seconds)}</span>
    </div>
  );
}

function toDigits(n: string): React.ReactFragment {
  return (
    <>
      <span className="digit">{n.charAt(0)}</span>
      <span className="digit">{n.charAt(1)}</span>
    </>
  );
}
